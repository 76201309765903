(function($, generic) {
  /**
   * This method is a getter or setter for changing or retrieving a cookie value.
   *
   * @class cookie
   * @namespace generic.cookie
   *
   * @param {string} name The name of the cookie to set or retrieve.
   * @param {string} value *OPTIONAL* When passed, this is the new value of the cookie.
   * @param {object} props *OPTIONAL* Object used to set additional props for the cookie.
   *
   * @return {string} When name is only passed, it will return the value of that cookie when available.
   */
  generic.cookie = function(name, value, props) {
    var c = document.cookie;
    if (arguments.length === 1) {
      var matches = c.match(new RegExp('(?:^|; )' + name + '=([^;]*)'));
      if (matches) {
        matches = decodeURIComponent(matches[1]);
        try {
          return JSON.parse(matches); //Object
        } catch (e) {
          return matches; //String
        }
      } else {
        return undefined;
      }
    } else {
      props = props || {};
      var exp = props.expires;
      if (typeof exp === 'number') {
        var d = new Date();
        d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000);
        exp = props.expires = d;
      }
      if (exp && exp.toUTCString) {
        props.expires = exp.toUTCString();
      }

      value = encodeURIComponent(value);
      var updatedCookie = name + '=' + value;
      var propName;

      for (propName in props) {
        updatedCookie += '; ' + propName;
        var propValue = props[propName];
        if (propValue !== true) {
          updatedCookie += '=' + propValue;
        }
      }

      document.cookie = updatedCookie;
    }
  };
})(jQuery, window.generic || {});

var generic = generic || {};

(function($) {
  if (!!window.localStorage) {
    var origSetItem = Storage.prototype.setItem;
    Object.defineProperty(Storage.prototype, 'setItem', {
      value: function() {
        var args = $.makeArray(arguments);
        if (!!args[0] && !!args[1]) {
          $(document).trigger('storage', [ args[0], args[1] ]);
        }
        origSetItem.apply(this, arguments);
      }
    });
  }
})(jQuery);

/**
 * Usage:
 *
 * var data = generic.data.get('SomeName');
 *
 * data.set('SomeName', { key: value }, {
 *   type: 'local',
 *   expires: function() {
 *     var date = new Date();
 *     date.setDate(date.getDate() + 1);  // today + 1 day
 *     return date;
 *   }
 * });
 */
generic.data = (function() {
  var data = {
    future: 9999999999,

    /**
     * Utility function
     */
    isFunction: function(val) {
      return !!(val && val.constructor && val.call && val.apply);
    },

    /**
     * Utility function
     */
    isEmpty: function(val) {
      var undef;
      var ev = [undef, null, false, 0, '', '0'];
      for (var i = 0, len = ev.length; i < len; i++) {
        if (val === ev[i]) {
          return true;
        }
      }
      return false;
    },

    /**
     * Utility function to detect an invalid storage type
     */
    isValidStoreType: function(type) {
      return !this.isEmpty(type) && ['local', 'session', 'cookie'].indexOf(type) != -1;
    },

    /**
     * Cookie storage object. It's a middle-man to the cookies.
     */
    cookieStorage: {
      getItem: function(name) {
        var c_start, c_end;
        if (document.cookie.length > 0) {
          c_start = document.cookie.indexOf(name + '=');
          if (c_start !== -1) {
            c_start = c_start + name.length + 1;
            c_end = document.cookie.indexOf(';', c_start);
            if (c_end === -1) {
              c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end)).replace(/^"(.*)"$/, '$1');
          }
        }
        return '';
      },

      setItem: function(name, value) {
        if (typeof value !== 'string') {
          value = JSON.parse(value);
        }
        var expires = value.expires;
        var date = new Date();
        date.setTime(expires * 1000); // data is passed as epoch seconds (not milli), so we multiply by 1000
        expires = ';expires=' + date.toGMTString();
        document.cookie = name + '=' + JSON.stringify(value).replace(/^"(.*)"$/, '$1') + expires + ';path=/';
      },

      removeItem: function(name) {
        this.setItem(name, '', -1);
      }
    },

    /**
     * Utility function to get the storage object from the type
     */
    getStore: function(key) {
      var map = {
        cookie: this.cookieStorage,
        local: window.localStorage,
        session: window.sessionStorage
      };

      var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      key = isIE11 ? 'cookie' : key;

      return map[key] || false;
    },

    /**
     * Getter
     */
    get: function(name, type) {
      name = !this.isEmpty(name) ? name : false;
      type = this.isValidStoreType(type) ? type : 'local';

      var store = this.getStore(type);

      if (!name || !store) {
        return '';
      }

      var data = store.getItem(name);

      if (!data) {
        return '';
      }

      if (typeof data !== 'string') {
        data = JSON.parse(data);
      }

      // Removing the expiration feature because the react components won't be calling these helper methods
      // expires = (type != 'cookie' && !!data.expires) ? data.expires : this.future;
      // return (now < expires && !!data.data) ? data.data : '';
      return data;
    },

    /**
     * Setter
     */
    set: function(name, value, options) {
      options = !this.isEmpty(options) ? options : {};
      var type = !!options.type && this.isValidStoreType(options.type) ? options.type : 'local';
      var store = this.getStore(type);

      if (this.isEmpty(name) || !value || !store) {
        return false;
      }

      // Removing the expiration feature because the react components won't be calling these helper methods
      // var expires = options.expires || this.future;
      // if (this.isFunction(expires)) {
      //   expires = Math.round(expires().getTime() / 1000.0);
      // }

      // store.setItem(name, JSON.stringify({
      //   expires : expires,
      //   data    : value
      // }));

      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }
      store.setItem(name, value);
    },

    /**
     * Remover
     */
    remove: function(name, type) {
      name = !this.isEmpty(name) ? name : false;
      type = this.isValidStoreType(type) ? type : 'local';

      var store = this.getStore(type);
      store.removeItem(name);
    }
  };

  return data;
})();

/**
 * (function($) {
 *
 * $(function() {
 *    generic.data.set('Cart', {'greg': 'local'}, {
 *      type: 'cookie',
 *      expires: function() {
 *        var date = new Date();
 *        date.setDate(date.getDate() + 1);  // today + 1 day
 *        return date;
 *      }
 *    });
 *    var d = generic.data.get('Cart', 'local');
 * 
 *    console.log(' ---- storage demo ---- ');
 *    console.log(d);
 * });
 *
 * })(jQuery);
 **/

(function($, generic) {
  generic.errorStateClassName = 'error';

  /**
   * This method displays error messages. It takes an array of error objects and a UL node
   * as parameters. If the UL is not spuulied, it will attempt to find a <UL class="error_messages">
   * in the DOM. It will then attempt to insert one directly after a <DIV id="header"> (If no header
   * is found, the method exits.) All the LI child nodes (previous messages) of the UL are hidden.
   * The text property of each error Hash is then displayed as an LI.
   * This method can also alter the style of the input elements that triggered the error.
   * The tags property in an error hash must be an array that contains a string starting with
   * "field." If the optional formNode parameter is supplied, this form node will be
   * searched for the field, and that field will be passed to the generic.showErrorState method.
   *
   * @example
   * var errArray = [
   *      {
   *          "text": "Last Name Alternate must use Kana characters.",
   *          "display_locations": [],
   *          "severity": "MESSAGE",
   *          "tags": ["FORM", "address", "field.last_name_alternate"],
   *          "key": "unicode_script.last_name_alternate.address"
   *      },
   *      {
   *          "text": "First Name Alternate must use Kana characters.",
   *          "display_locations": [],
   *          "severity": "MESSAGE",
   *          "tags": ["FORM", "address", "field.first_name_alternate"],
   *          "key": "unicode_script.first_name_alternate.address"
   *      }
   *  ];
   * var listNode = $$("ul.error_messages")[0];
   * generic.showErrors(errArray, listNode);
   * @param {Array} errorObjectsArray Array of error hashes.
   * @param {DOM} errListNode UL element in which the error messages will be displayed.
   * @param {DOM} formNode Form element (or any container node) that contains the inputs
   * to be marked as being in an error state.
   *
   * @return {undefined}
   */
  generic.showErrors = function(errorObjectsArray, errListNode, formNode) {
    var ulNode = errListNode != null ? errListNode : $('ul.error_messages');
    // prototype version acted on a single node. This could be a list
    // so cut it down to avoid redundant messaging in places. i.e - signin
    ulNode = $(ulNode[0]);

    if ($('ul.error_messages').length === 0) {
      var header = $('div#header');
      if (header.length === 0) {
        return null;
      } else {
        $("<ul class='error_messages'></ul>").insertAfter(header);
        ulNode = $('.error_messages');
      }
    }
    var errListItemNodes = ulNode.children('li');

    errListItemNodes.hide();
    if (errorObjectsArray.length > 0) {
      // hide all error states on fields
      formNode = $(formNode);
      var inputNodes = formNode.children('input, select, label');
      inputNodes.each(function() {
        generic.hideErrorState(this);
      });
    }
    for (var i = 0, len = errorObjectsArray.length; i < len; i++) {
      var errObj = errorObjectsArray[i];
      var errKey = errObj.key;
      var errListItemNode = [];
      if (errKey) {
        var regEx = new RegExp(errKey);
        // try to find LI whose ID matches the error key
        errListItemNode = errListItemNodes.filter(function() {
          return regEx.test(this.id);
        });
      }

      if (errListItemNode.length > 0) {
        errListItemNode.show();
      } else {
        errListItemNode = $('<li/>');
        errListItemNode.html(errObj.text);
        ulNode.append(errListItemNode);
      }
      if (errObj.displayMode && errObj.displayMode === 'message') {
        errListItemNode.addClass('message');
      }
      if (errObj.tags && Array.isArray(errObj.tags)) {
        // search through error objects, show error state for any tagged with "field.[NAME]"
        var fieldPrefixRegEx = /^field\.(\w+)$/;
        for (var j = 0, jlen = errObj.tags.length; j < jlen; j++) {
          var tag = errObj.tags[j];
          var reResults = tag.match(fieldPrefixRegEx);
          if (reResults && reResults[1]) {
            var fieldName = reResults[1].toUpperCase();
            var inputNode = $('input[name=' + fieldName + '], select[name=' + fieldName + ']', formNode);
            if (inputNode.length > 0) {
              generic.showErrorState(inputNode[0]);
              var labelNode = $('label[for=' + inputNode[0].id + ']', formNode);
              generic.showErrorState(labelNode[0]);
            }
          }
        }
      }
    }
    ulNode.show();
    ulNode.addClass('error_messages_display');
  };

  generic.showErrorState = function(/* DOM Node */ inputNode) {
    if (!inputNode || !generic.isElement(inputNode)) {
      return null;
    }
    $(inputNode).addClass(generic.errorStateClassName);
  };

  generic.hideErrorState = function(/* DOM Node */ inputNode) {
    if (!inputNode || !generic.isElement(inputNode)) {
      return null;
    }
    $(inputNode).removeClass(generic.errorStateClassName);
  };
})(jQuery, window.generic || {});

(function($, generic) {
  /**
   * generic.forms contains helper methods to change different form input types
   * defined within the forms object.
   *
   * @class forms
   * @namespace generic.forms
   *
   * @return {undefined}
   */
  generic.forms = {
    select: {
      addOption: function(args) {
        if (!args) {
          return;
        }
        var val = args.value;
        var label = args.label || val;
        var opt = '<option value="' + val + '">' + label + '</option>';
        args.menuNode.append(opt);
      },
      setValue: function(args) {
        var idx = 0;
        for (var i = 0, len = args.menuNode[0].options.length; i < len; i++) {
          if (args.value === args.menuNode[0].options[i].value) {
            idx = i;
            break;
          }
        }
        args.menuNode[0].selectedIndex = idx;
      }
    }
  };
})(jQuery, window.generic || {});

(function($, generic, rb) {
  generic.rb = generic.rb || {};

  /**
    * This method provides access to resource bundle values that have been
    * written to the HTML in JSON format. The file that outputs these values
    * must be included in the .html as a script tag with the desired RB name
    * as a query string parameter.
    *
    * @class ResourceBundle
    * @namespace generic.rb
    *
    * @memberOf generic
    * @methodOf generic
    * @requires generic.Hash
    *
    * @example Inline data
    *
    *    <script src="/js/shared/v2/internal/resource.tmpl?rb=account"></script>
    *
    * @example Script retrieval of data values
    *
    *    var myBundle = generic.rb("account");
    *    myBundle.get("err_please_sign_in");
    *
    * @param {String} rbGroupName name of resource bundle needed
    *
    * @returns {Object} An object that provides the main get method
    *
    */
  generic.rb = function(rbGroupName) {
    var findResourceBundle = function(groupName) {
      if (groupName && rb) {
        var rbName = groupName;
        var rbHash = generic.Hash(rb[rbName]);
        if (rbHash) {
          return rbHash;
        } else {
          return {};
        }
      } else {
        return {};
      }
    };

    var resourceBundle = findResourceBundle(rbGroupName);

    var returnObj = {
      /**
      * @public This method will return the value for the requested Resource Bundle key.
      * If the key is not found, the key name will be returned.
      *
      * @param {String} keyName key of desired Resource Bundle value
      *
      * @returns {String} The value of the key or the key name itself.
      *
      */
      get: function(keyName) {
        if (!generic.isString(keyName)) {
          return null;
        }
        var val = resourceBundle.get(keyName);
        if (val) {
          return val;
        } else {
          return keyName;
        }
      }
    };
    return returnObj;
  };
})(jQuery, window.generic || {}, window.rb || {});

var $H = $H || {};

(function($, generic) {
  /**
   * @description Wrap Function - Return a new function that triggers a parameter function first and
   * then moves on to the original, wrapped function.  The follow up of the original can be
   * precluded by returning false from the parameter of type function.
   *
   **/
  $.extend(Function.prototype, {
    /**
     * @param {function} step-ahead function to the original function being wrapped
     * @return {function} new function to be assigned to original namespace
     */
    wrap: function(fn) {
      var _generic_ = fn; // generic-level
      var _site_ = this; // site-level

      var passObj = true;

      return function() {
        passObj = _generic_.apply(fn, arguments);
        if (passObj) {
          _site_.call(this, passObj);
        } else {
          return;
        }
      };
    }
  });

  /**
   * @description Minimal Native Version of Prototype Hash Class
   *
   * @class Hash
   * @namespace generic.Hash
   *
   * @returns A public api object (get, set, etc).
   *
   */
  generic.Hash = function(obj) {
    var H = obj instanceof Object ? obj : {}, index = [], _queue = [];
    var queryString = function() {
      /** @inner **/
      var Q = function(o, v, isArr) {
        var i, S = Object.prototype.toString, A = '[object Array]', _queue = [];
        o = o || H;
        for (i in o) {
          var n;
          if (typeof o[i] === 'object') {
            _queue = S.call(o[i]) === A ? Q(o[i], i, true) : Q(o[i], i);
          } else {
            n = isArr ? v : i; _queue.push(n + '=' + o[i]);
          }
        }
        return _queue;
      };
      return Q().join('&');
    };

    return {
    /**
     * @public get
     */
      get: function(x) {
        return H[x] || false;
      },
      /**
     * @public set
     */
      set: function(x, y) {
        H[x] = y; index.push(x); return this;
      },
      /**
       * @public toQueryString
       */
      toQueryString: queryString,
      /**
     * @public fromQueryString
     */
      queryToJson: function(q) {
        var query = q;
        var k, v, i;
        var obj = {};

        var xArr = query.split('&');

        for (i = 0; i < xArr.length; i++) {
          k = xArr[i].split('=')[0]; v = xArr[i].split('=')[1];
          var evalStr = "obj['" + k + "']='" + v + "'";
          eval(evalStr);
        }
        return obj;
      },

      /**
     * @public slice
     *
     * @param {array}
     * @returns hash containing only the key/value pairs matched by the keys
     *          passed in the array
     *
     */
      slice: function(array) {
        // @todo: $H needs replacement
        var h = $H();
        for (var i in array) {
          h.set(array[i], H[array[i]]);
        }
        return h;
      },

      obj: function() {
        return H;
      }
    }; // end api set
  };

  generic.HashFactory = function(hash) {
    var H = new generic.Hash(hash);
    return H;
  };

  /**
   * @see generic.Hash
   */
  $H = generic.HashFactory; // map convenience alias

  /**
   * Minimal Native Version of Prototype Class
   *
   * @deprecated Jquery extend method has options for deep copy extensions
   *
   * @class Class
   * @namespace generic.Class
   *
   */
  generic.Class = { // Uppercase 'Class', avoid IE errors

    fn: function(src, props) {
      var tgt, prxy, xyz, z, fnTest = /xyz/.test(function() {
        xyz;
      }) ? /\b_super\b/ : /.*/;

      tgt = function() { // New Constructor
        // Initialize Method is a Requirement of Class
        // With the inclusion of the _super method, initialize in the superclass should only be called on demand
        /*if(tgt.superclass&&tgt.superclass.hasOwnProperty("initialize")){
                tgt.superclass.initialize.apply(this,arguments);
            }*/
        if (tgt.prototype.initialize) {
          tgt.prototype.initialize.apply(this, arguments);
        }
      };

      // Preserve Classical Inheritance using Proxy Middle
      src = src || Object;
      prxy = function() {}; /* Potentially define "Class" here */
      prxy.prototype = src.prototype;
      tgt.prototype = new prxy();
      tgt.superclass = src.prototype;
      tgt.prototype.constructor = tgt;

      // give new class 'own' copies of props and add _super method to call superclass' corresponding method
      for (z in props) {
        if (typeof props[z] === 'function' && typeof tgt.superclass[z] === 'function' && fnTest.test(props[z])) {
          tgt.prototype[z] = (function(z, fn) {
            return function() {
              this._super = tgt.superclass[z];
              var ret = fn.apply(this, arguments);
              return ret;
            };
          })(z, props[z]);
        } else {
          tgt.prototype[z] = props[z];
        }
      }

      return tgt;
    },
    create: function() {
      var len = arguments.length, args = Array.prototype.slice.call(arguments);
      var tgt;

      if (len === 2) {
        tgt = generic.Class.fn(args[0], args[1]);
      } else if (len === 1) {
        tgt = generic.Class.fn(null, args[0]);
      } else {
        tgt = function() {}; /* return empty constructor */
      }

      return tgt; // return constructor that stacks named Class w/ object-literal, works with instanceof
    }, // End Create Method
    mixin: function(baseClass, mixin) {
      var newClass = baseClass;
      if (mixin && mixin.length) {
        for (var i = 0; i < mixin.length; i++) {
          newClass = generic.Class.mixin(newClass, mixin[i]);
        }
      } else {
        if (mixin) {
          newClass = generic.Class.create(newClass, mixin);
        }
      }
      return newClass;
    }
  };

  /**
   * @memberOf generic
   *
   */
  generic.isElement = function(o) {
    return o.nodeType && (o.nodeType === 1);
  };

  /**
   * @memberOf generic
   *
   */
  generic.isString = function(s) {
    return typeof s === 'string';
  };

  /**
   * @memberOf generic
   *
   */
  generic.env = {
    isIE: !!(typeof ActiveXObject === 'function'),
    isIE6: !!(!!(typeof ActiveXObject === 'function') && /MSIE\s6\.0/.test(navigator.appVersion)),
    isFF: !!(typeof navigator.product !== 'undefined' && navigator.product === 'Gecko' && !(document.childNodes && !navigator.taintEnabled) && /firefox/.test(navigator.userAgent.toLowerCase())),
    isFF2: !!(typeof navigator.product !== 'undefined' && navigator.product === 'Gecko' && !(document.childNodes && !navigator.taintEnabled) && navigator.userAgent.toLowerCase().split(' firefox/').length > 1 && navigator.userAgent.toLowerCase().split(' firefox/')[1].split('.')[0] === '2'),
    isFF3: !!(typeof navigator.product !== 'undefined' && navigator.product === 'Gecko' && !(document.childNodes && !navigator.taintEnabled) && navigator.userAgent.toLowerCase().split(' firefox/').length > 1 && navigator.userAgent.toLowerCase().split(' firefox/')[1].split('.')[0] === '3'),
    isMac: !!/macppc|macintel/.test(navigator.platform.toLowerCase()),
    isSafari: !!/Safari/.test(navigator.userAgent),

    domain: window.location.protocol + '//' + window.location.hostname,

    debug: true, //JSTest check subdomain

    parsedQuery: function() {
      var query = window.location.search.toString().split('?')[1] || '';
      var splitStr = query.split('&');
      var key, value, tempObj, tempStr;
      var a = {}; a.n = {};
      var main = function() {
        var params = {};
        var arr = [];

        if (!query) {
          return;
        }

        for (var i = 0; i < splitStr.length; i++) {
        // just take the key
          key = splitStr[i].split('=')[0];
          value = splitStr[i].split('=')[1];

          var c = splitStr[i].match(new RegExp(key));
          var cItem = a.n[c] = a.n[c] || { 'v': [], 'key': c };
          cItem.e = cItem.e ? cItem.e + 1 : 0;
          cItem.v.push(value);
        }

        for (var namespace in a.n) {
        // if duplicate keys
          if (a.n[namespace].e > 0) {
            for (var n = 0; n <= a.n[namespace].e; n++) {
              arr.push(a.n[namespace].v.pop());
            } // end for-loop
            a.n[namespace].v = arr;
          }
          tempObj = a.n[namespace].v;

          if (tempObj.length > 1) {
            eval('params["' + namespace + '"]=tempObj');
          } else {
            tempStr = tempObj[0]; eval('params["' + namespace + '"]=tempStr');
          }
        }
        return params;
      };

      var parameters = main() || {};
      return parameters;
    },
    query: function(key) {
      var result = generic.env.parsedQuery()[key] || null;
      return result;
    }
  };
})(jQuery, window.generic || {});
